<template>
   <YLayoutBodyMenu :menu="[
       {title:'基础配置',path:'/shop/plugin/dealer/conf'},
       {title:'经销商管理',path:'/shop/plugin/dealer/manage'},
       {title:'分红日志',path:'/shop/plugin/dealer/log'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>